/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { format } from 'date-fns';
import generateAssociatedElementsData from '../utils/generateAssociatedElementsData.js';
import generateEntitiesData from '../utils/generateEntitiesData.js';
import { createIndicatorOptions, parseHeader } from '../utils/utils.js';
import {
  getData,
  putData,
  postData,
  deleteData,
  API_URL,
} from './index.js';
import { updateIndicator } from './indicators.js';

import {
  DASHBOARDS_GET,
  DASHBOARDS_GET_BY_ID,
  DASHBOARD_POST,
  DASHBOARD_PUT,
  DASHBOARD_DUPLICATE,
  DASHBOARD_LOADING,
  DASHBOARD_DELETE,
  DASHBOARD_ERROR,
  GET_LIST,
  GET_USER,
  SET_TOAST,
  DASHBOARD_UPDATE_ERRORS,
  INDICATOR_RESET,
  DASHBOARDS_UPDATE_FAVORITE,
} from './types';

const getUser = async (dispatch, search) => {
  const url = `/craft/user/${search}`;
  const response = await getData(DASHBOARD_ERROR, url, dispatch, true);
  if (response.data.user) {
    dispatch({
      type: GET_USER,
      payload: response.data.user,
    });
  }
};

const getDashboardList = async (dispatch) => {
  const url = '/dashboards/list';
  const response = await getData(DASHBOARD_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_LIST,
      payload: response.data,
    });
  }
};

const getDashboardById = async (dispatch, id) => {
  const url = `/dashboards/${id}`;
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARDS_GET_BY_ID,
  });
  const response = await getData(DASHBOARD_ERROR, url, dispatch, true);
  if (response.data?.dashboard) {
    dispatch({
      type: DASHBOARDS_GET_BY_ID,
      payload: response.data,
    });
  }
};

const getDashboards = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/dashboards${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARDS_GET,
  });
  const response = await getData(DASHBOARD_ERROR, url, dispatch, true);
  if (response.data?.dashboards) {
    dispatch({
      type: DASHBOARDS_GET,
      payload: response.data,
    });
  }
};

const createDashboard = async (dispatch, data) => {
  const url = '/dashboards';
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARD_POST,
  });
  const response = await postData(DASHBOARD_ERROR, url, dispatch, data, true);
  if (response.data?.dashboard) {
    dispatch({
      type: DASHBOARD_POST,
      payload: response.data.dashboard,
    });
  }
  return response;
};

const duplicateDashboard = async (dispatch, id, data) => {
  const url = `/dashboards/duplicate/${id}`;
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARD_DUPLICATE,
  });
  const response = await postData(DASHBOARD_ERROR, url, dispatch, data, true);
  if (response.data?.dashboard) {
    dispatch({
      type: DASHBOARD_DUPLICATE,
      payload: response.data.dashboard,
    });
  }
  return response;
};

const updateDashboard = async (dispatch, data, id) => {
  const url = `/dashboards/${id}`;
  dispatch({
    type: DASHBOARD_LOADING,
    payload: `${DASHBOARD_PUT}-${id}`,
  });
  const response = await putData(
    DASHBOARD_ERROR,
    url,
    dispatch,
    { ...data },
    true,
  );
  if (response.data?.dashboard) {
    dispatch({
      type: DASHBOARD_PUT,
      payload: response.data,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Le dashboard a été modifié',
      },
    });
  }
  return response;
};

async function handleChangeData(options, config, graphType, data) {
  const isAssociatedDb = !!config?.name?.value?.parent;
  let generatedData = null;
  if (!isAssociatedDb) {
    generatedData = await generateEntitiesData(
      options,
      config,
      graphType,
      data,
    );
  } else {
    generatedData = await generateAssociatedElementsData(
      options,
      config,
      graphType,
      data,
    );
  }
  return generatedData;
}

const updateDashboardData = async (dispatch, dataset, dashboard, isNewKey) => {
  const errors = [];
  const tasks = [];
  dashboard.indicators.forEach((indicator) => {
    if ((isNewKey && indicator.inheritance) || !isNewKey) {
      tasks.push(async () => {
        const lang = localStorage.getItem('lang');
        const {
          header, query, graph,
        } = indicator;
        // GET FIELDS
        const config = { ...indicator.config };
        const body = {
          lang,
          key: isNewKey ? dataset?.value : indicator?.key,
          request: isNewKey ? dataset?.api : indicator?.requests,
          header: parseHeader(header),
          query: query ? encodeURI(query) : '',
        };
        const response = await axios.post(`${API_URL}/craft/fields`, body);
        const { fields } = response.data;
        // GET DATAS
        const field = fields?.find(
          (f) => f.value === config?.params?.name,
        );

        if (config?.endDate && !isNewKey) {
          const today = new Date();
          const end = today.setDate(today.getDate() - 1);
          config.endDate = format(new Date(end), 'yyyy-MM-dd');
        }
        const res = await axios.post(`${API_URL}/craft/request`, {
          key: isNewKey ? dataset?.value : indicator?.key,
          requests: isNewKey ? dataset?.api : indicator?.requests,
          lang,
          query,
          header: parseHeader(header),
          associatedDomainId: field?.associatedDomainId || false,
          associatedId: field?.associatedId || false,
          startDate: config?.startDate,
          endDate: config?.endDate,
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            dispatch({
              type: SET_TOAST,
              payload: {
                type: 'error',
                message: 'toaster.errors.500',
              },
            });
          }
        });
        const { data } = res.data;
        const options = await createIndicatorOptions(data, fields);
        const name = options?.keys?.find(
          (k) => k.value === config?.params?.name?.value || k.value === config.params.name,
        );
        const value = options?.options?.find(
          (k) => k.value === config.params?.value?.value || k.value === config.params.value,
        );
        if (name?.value && value.value) {
          config.params.name = name;
          config.params.value = value;
          const updatedConfig = {
            ...config,
          };
          if (isNewKey) {
            updatedConfig.associetedConfig = {
              api: dataset?.api,
              key: dataset?.value,
            };
          }
          const generatedData = await handleChangeData(
            options,
            updatedConfig,
            graph,
            indicator.data,
          );
          if (generatedData.stacked) {
            config.legend.stacked = generatedData.stacked;
          }
          const updatedData = {
            ...indicator,
            key: isNewKey ? dataset?.value : indicator?.key,
            requests: isNewKey ? dataset?.api : indicator?.requests,
            config,
            data: generatedData.data,
          };
          if (generatedData.data.length === 0) {
            updatedData.isPublished = false;
            errors.push({
              value: config.params.name.value,
              indicator: indicator.name,
            });
          }
          await updateIndicator(dispatch, updatedData, indicator._id);
        } else {
          const updatedData = {
            ...indicator,
            key: isNewKey ? dataset?.value : indicator?.key,
            requests: isNewKey ? dataset?.api : indicator?.requests,
            isPublished: false,
            config,
            data: [],
          };
          await updateIndicator(dispatch, updatedData, indicator._id);
          errors.push({
            value: config.params.name,
            indicator: indicator.name,
          });
        }
      });
    }
  });
  for await (const task of tasks) {
    try {
      await task();
    } catch (error) {
      console.log(error);
    }
  }
  dispatch({
    type: INDICATOR_RESET,
  });
  await updateDashboard(dispatch, {
    key: dataset?.value,
    requests: dataset?.api,
  }, dashboard._id);
  if (errors.length > 0) {
    return dispatch({
      type: DASHBOARD_UPDATE_ERRORS,
      payload: errors,
    });
  }
  return dispatch({
    type: DASHBOARD_UPDATE_ERRORS,
    payload: null,
  });
};

const updateFavoriteDashboard = async (dispatch, data) => {
  const url = '/dashboards/favorite';
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARDS_UPDATE_FAVORITE,
  });
  const response = await postData(DASHBOARD_ERROR, url, dispatch, data, true);
  if (response.data?.dashboards) {
    dispatch({
      type: DASHBOARDS_UPDATE_FAVORITE,
      payload: response.data.dashboards,
    });
  }
  return response;
};

const deleteDashboard = async (dispatch, id) => {
  const url = `/dashboards/${id}`;
  dispatch({
    type: DASHBOARD_LOADING,
    payload: DASHBOARD_DELETE,
  });
  const response = await deleteData(DASHBOARD_ERROR, url, dispatch, true);
  if (response.status === 200) {
    dispatch({
      type: DASHBOARD_DELETE,
      payload: id,
    });
  }
  return response;
};

export {
  getUser,
  getDashboardList,
  getDashboardById,
  getDashboards,
  createDashboard,
  duplicateDashboard,
  updateDashboardData,
  updateFavoriteDashboard,
  updateDashboard,
  deleteDashboard,
};
