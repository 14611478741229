import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// import { isValidUrl } from '../../../utils/utils';

import {
  InputText,
  ErrorField,
  StyledSelect,
  StyledSwitch,
  InputDate,
  // Textarea,
} from '../../../lib/HooksFormFields';
import { parseHeader, createIndicatorOptions } from '../../../utils/utils';
import { getAssociatedDbAction, getIndicatorsFieldsAction } from '../../../actions/indicators';

import styles from './data.module.scss';
import { API_URL } from '../../../actions';
import { INDICATORS_ASSOCIETED_DB, SET_TOAST } from '../../../actions/types';
import Loader from '../../../components/Loader';
import generateEntitiesData from '../../../utils/generateEntitiesData';
import generateAssociatedElementsData from '../../../utils/generateAssociatedElementsData';

const Data = () => {
  const {
    control, errors, watch, setValue, setError, submit,
  } = useOutletContext();
  const params = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n?.language || 'fr';
  const { dashboardReducer, indicatorReducer } = useSelector((store) => store);
  const { dashboard, user } = dashboardReducer;
  const { indicator, associatedDbs, requestLoading } = indicatorReducer;
  const today = new Date();
  const date = new Date(today?.setDate(today.getDate() - 1)).toISOString().split('T')[0];

  const currentForm = watch();
  const api = watch('api');
  const dataset = watch('dataset');
  const query = watch('query');
  const graph = watch('graph');
  const prevData = watch('data');

  function handleChangeSource(source) {
    if (source === 'associetedDb') {
      setValue('config.isAssociatedDb', true);
      setValue('config.isStat', false);
      setValue('config.startDate', null);
      setValue('config.endDate', null);
    } else if (source === 'stat') {
      setValue('config.isAssociatedDb', false);
      setValue('config.isStat', true);
      setValue('config.startDate', '2020-01-01');
      setValue('config.endDate', date);
      setValue('api', null);
      setValue('dataset', null);
    } else {
      setValue('config.isAssociatedDb', false);
      setValue('config.isStat', false);
      setValue('config.startDate', null);
      setValue('config.endDate', null);
    }
  }

  async function handleChangeData(
    options,
    config,
    graphType,
    data,
  ) {
    const isAssociatedDb = !!config?.name?.value?.parent;
    let generatedData = null;
    const associatedConfig = {
      ...config,
      associetedConfig: {
        api: watch('api')?.value,
        key: watch('dataset')?.value,
      },
    };
    if (!isAssociatedDb) {
      generatedData = await generateEntitiesData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    } else {
      generatedData = await generateAssociatedElementsData(
        options,
        associatedConfig,
        graphType,
        data,
      );
    }
    return generatedData;
  }

  async function handleChangeIndicator() {
    try {
      await submit();
      const headers = {
        lang,
        key: dataset?.value,
        request: dataset?.api,
        header: parseHeader(indicator?.header),
        query: query ? encodeURI(query) : '',
        associatedId: currentForm?.config?.associatedDb?.value?.associatedDb,
        associatedDomainId: currentForm?.config?.associatedDb?.value?.associatedDomainId,
      };
      const fieldsData = await getIndicatorsFieldsAction(
        dispatch,
        headers,
      );

      const name = currentForm?.config?.params?.name;

      const field = fieldsData?.find(
        (f) => f.value === name,
      );

      const res = await axios.post(`${API_URL}/craft/request`, {
        key: dataset?.value,
        requests: dataset?.api,
        lang,
        query: query ? encodeURI(query) : '',
        header: parseHeader(indicator?.header),
        associatedDomainId: field?.associatedDomainId,
        associatedId: field?.associatedId,
        startDate: currentForm?.config?.startDate,
        endDate: currentForm?.config?.endDate,
      });
      const { data } = res.data;
      console.log(name);
      const noDataFound = name !== 'all' && !name?.value?.includes('aggregation') && data?.entities?.filter((d) => (
        // eslint-disable-next-line no-prototype-builtins
        d.hasOwnProperty(name) ? d : null))?.length === 0;
      if (noDataFound) {
        return setError('noData', { message: `Aucune donné trouvés "${name}"` });
      }

      const options = await createIndicatorOptions(data, fieldsData);

      const updatedConfig = {
        ...currentForm?.config,
        associetedConfig: {
          api: dataset?.api,
          key: dataset?.value,
        },
      };
      const generatedData = await handleChangeData(
        options,
        updatedConfig,
        graph,
        prevData,
      );

      if (generatedData.stacked) {
        setValue('config.legend.stacked', generatedData.stacked);
      }
      if (generatedData.data) {
        setValue('data', generatedData.data);
        return dispatch({
          type: SET_TOAST,
          payload: {
            type: 'success',
            message: "L'indicateur a été modifié",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'error',
          message: err?.response?.data?.error || 'toaster.errors.500',
        },
      });
    }
    return null;
  }

  useEffect(() => {
    if (api?.value) {
      setValue('requests', api.value);
    }
  }, [api]);

  useEffect(() => {
    if (dataset?.value) {
      setValue('key', dataset.value);
      if (!currentForm?.config?.isStat) {
        const headers = {
          lang,
          key: dataset?.value,
          requests: dataset?.api,
          header: parseHeader(indicator?.header),
          query: query ? encodeURI(query) : '',
        };
        getAssociatedDbAction(dispatch, headers);
      }
    }
  }, [dataset]);

  useEffect(() => {
    if (indicator?.key && indicator?.key !== dataset?.value) {
      setValue('inheritance', false);
      dispatch({
        type: INDICATORS_ASSOCIETED_DB,
        payload: null,
      });
    }
  }, [dataset?.value, indicator?.key]);

  return (
    <div className={styles.data}>
      {(user?.apis && ((params.indicatorId)
        || (dashboard && !params.indicatorId)) && !requestLoading
      ) ? (
        <>
          <div className={styles.row}>
            <div className={styles.sources}>
              <button
                className={`${!currentForm.config?.isAssociatedDb && !currentForm.config?.isStat ? styles.active : ''}`}
                onClick={() => handleChangeSource()}
              >
                {t('indicator.isEntities')}
              </button>
              <button
                className={`${currentForm.config?.isAssociatedDb && !currentForm.config?.isStat ? styles.active : ''}`}
                onClick={() => handleChangeSource('associetedDb')}
              >
                {t('indicator.isAssociatedDb')}
              </button>
              <button
                className={`${!currentForm.config?.isAssociatedDb
                  && currentForm?.config?.isStat ? styles.active : ''}`}
                onClick={() => handleChangeSource('stat')}
              >
                {t('indicator.isStats')}
              </button>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <StyledSelect
                name="api"
                control={control}
                label={t('indicator.api')}
                isSearchable
                options={currentForm?.config?.isStat ? user?.stats : user?.apis}
              />

              {errors?.requests?.message && <ErrorField message={errors.requests.message} />}
            </div>
            <div className={styles.containerField}>
              <div>
                <StyledSelect
                  name="dataset"
                  control={control}
                  label={t('indicator.dataset')}
                  isSearchable
                  options={user?.keys.filter((k) => k.api === api?.value) || []}
                />
              </div>
              {(dataset?.value !== indicator?.key
                || query !== indicator?.query
                || indicator?.config?.endDate !== currentForm?.config?.endDate
                || indicator?.config?.startDate !== currentForm?.config?.startDate)
                && indicator?.data
                && <div className={styles.update}>
                    <button
                    type='button'
                    onClick={() => handleChangeIndicator()}
                  >
                    {t('indicator.update')}
                  </button>
                  {errors?.noData?.message
                      && <ErrorField message={errors.noData.message} />}
                </div>
              }
              {errors?.key?.message && <ErrorField message={errors.key.message} />}
            </div>
          </div>
          {currentForm.config?.isAssociatedDb
            && <div className={styles.row}>
                <div className={styles.containerField}>
                <StyledSelect
                  name="config.associatedDb"
                  control={control}
                  label={t('indicator.associatedDb')}
                  isSearchable
                  options={associatedDbs}
                />
                {errors?.requests?.message && <ErrorField message={errors.requests.message} />}
              </div>
            </div>
          }
          <div className={`${styles.containerField} ${styles.switch}`}>
            <StyledSwitch
              name="inheritance"
              control={control}
              defaultValue={indicator?.inheritance}
            />
            <p>{t('indicator.inheritance')}</p>
          </div>
          {!currentForm?.config?.isStat
            && <div className={styles.containerField}>
              <div>
                <InputText
                  name="query"
                  control={control}
                  label={t('indicator.search')}
                  placeholder={'?criteria='}
                  rules={{}}
                />
              </div>
            </div>
          }
          {currentForm?.config?.isStat
            && <>
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputDate
                      name="config.startDate"
                      control={control}
                      label={t('indicator.startDate')}
                      maxDate={date}
                      placeholder={''}
                      rules={{
                        required: t('input.required'),
                      }}
                    />
                    {errors?.config?.startDate?.message
                    && <ErrorField message={errors?.config?.startDate?.message} />}
                </div>
                <div className={styles.containerField}>
                  <InputDate
                    name="config.endDate"
                    control={control}
                    maxDate={date}
                    label={t('indicator.endDate')}
                    placeholder={''}
                    rules={{
                      required: t('input.required'),
                    }}
                  />
                  {errors?.config?.endDate?.message
                    && <ErrorField message={errors?.config?.endDate?.message} />}
              </div>
              </div>
              <div className={`${styles.row} ${styles.end}`}>
                <button
                  type='button'
                  onClick={async () => {
                    await setValue('config.endDate', date);
                    console.log(watch('config.endDate'));
                    await submit();
                    console.log('submited');
                  }}
                >
                  {t('indicator.currentDateUpdate')}
                </button>
              </div>
          </>
          }
        </>
        ) : (<div className={styles.loader}>
        <Loader />
      </div>)}
    </div>
  );
};

export default Data;
